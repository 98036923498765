import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Header from './Header'
import Footer from './Footer'
// import NotificationBar from './NotificationBar'

import '../scss/style.scss'

if (typeof window !== 'undefined') {
  var SmoothScroll = require('smooth-scroll')
  var smoothScroll = new SmoothScroll('[data-scroll') // eslint-disable-line
}

const LayoutBody = ({ children, location, match }) => (
  <StaticQuery
    query={graphql`
         query LayoutQuery {
          site {
            siteMetadata {
              title
              navigation {
                path
                externalLink
                label
                color
                isNavItem
              }
              footerNavigation {
                groupTitle
                items {
                  path
                  externalLink
                  label
                  target
                }
              }
            }
          }
         }
       `}
    render={data => (
      <div>
        <Helmet title={data.site.siteMetadata.title}>
          <meta content='Tierion: Blockchain Proof Engine' property='og:title' />
          <meta content='Tierion turns the blockchain into a global platform for verifying any data, file, or business process.' property='og:description' />
          <meta content='https://tierion.com' property='og:url' />
          <meta content='https://content.tierion.com/images/facebook-open-graph.png' property='og:image' />
          <meta content='summary_large_image' name='twitter:card' />
          <meta content='@Tierion' name='twitter:site' />
          <meta content='Tierion: Blockchain Proof Engine' name='twitter:title' />
          <meta content='Tierion turns the blockchain into a global platform for verifying any data, file, or business process.' name='twitter:description' />
          <meta content='http://content.tierion.com/images/tierion-twitter-large.png' name='twitter:image:src' />
          {/* Global site tag (gtag.js) - Google Analytics  */}
          <script async src="https://www.googletagmanager.com/gtag/js?id=UA-63126547-1"></script>
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'UA-63126547-1');
            `}
          </script>
        </Helmet>

        <Header {...data.site.siteMetadata} location={location} />
        <main className='page-container'>
          {children}
        </main>
        <Footer {...data.site.siteMetadata} />
      </div>
    )}
  />
)

class Layout extends React.Component {
  componentDidMount () {
    window.addEventListener('load', () => {
      document.body.classList.add('is-loaded')
    })

    // add touch class if touch detected, then remove listener
    const onFirstTouch = () => {
      document.body.classList.add('touch-supported')
      window.removeEventListener('touchstart', onFirstTouch)
    }

    window.addEventListener('touchstart', onFirstTouch)
  }

  render () {
    return (
      <LayoutBody {...this.props} />
    )
  }
}

export default Layout
