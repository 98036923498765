import React from 'react'
import {Link} from 'gatsby'
import get from 'lodash/get'
import classnames from 'classnames'

import Icon from '../components/Icon'
import { getActiveNavObject, importAll } from '../utils/helpers'

const pageImgs = importAll(require.context('../img/global', false, /\.(png|jpe?g|svg)$/))

class Header extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      navVisible: false
    }

    this.toggleNav = this.toggleNav.bind(this)
  }

  getColorScheme () {
    const active = getActiveNavObject(this.props)
    const color = get(active, 'color')
    return color === 'light' ? 'navbar-dark' : 'navbar-light'
  }

  toggleNav (event) {
    this.setState({
      navVisible: !this.state.navVisible
    })
  }

  renderNavItems (items) {
    return items.map((item, index) => {
      if (item.path === '/' || (!item.isNavItem)) return null
      const hasImg = Object.keys(pageImgs).some((key) => {
        return key.indexOf(item.path) !== -1
      })
      const NavItem = () => {
        return item.externalLink
          ? (
            <a
              href={item.path}
              className='nav-link'
              onClick={this.toggleNav}
              rel='noopener'
            >
              {hasImg && <img src={pageImgs[`.${item.path}-mobile-nav.svg`]} alt='Mobile Nav Toggle' />}
              <span>{item.label}</span>
            </a>
          )
          : (
            <Link
              to={item.path}
              className='nav-link'
              activeClassName='active'
              onClick={this.toggleNav}
            >
              {hasImg && <img src={pageImgs[`.${item.path}-mobile-nav.svg`]} alt='Mobile Nav Toggle' />}
              <span>{item.label}</span>
            </Link>
          )
      }
      return (
        <li key={index} className='nav-item'>
          <NavItem />
        </li>
      )
    })
  }

  render () {
    const { title, navigation } = this.props
    const navClass = classnames(
      'nav-menu', {
        'is-visible': this.state.navVisible
      })

    return (
      <nav className={`navbar navbar-expand flex-column flex-md-row ${this.getColorScheme()}`}>
        <div className='container'>
          <Link className='navbar-brand mb-0' to='/'>
            <span className='sr-only'>{title}</span>
            <Icon type={this.getColorScheme() === 'navbar-dark' ? 'logo-white' : 'logo-black'} />
          </Link>
          <div className='nav-menu-container'>
            <div className={navClass}>
              <div className='mobile-nav-label'>Products</div>
              <ul className='navbar-nav bd-navbar-nav flex-row'>
                {this.renderNavItems(navigation)}
              </ul>
            </div>
            <button className='nav-burger' onClick={this.toggleNav}>
              <Icon type={this.state.navVisible ? 'close' : 'burger'} />
            </button>
          </div>
        </div>
      </nav>
    )
  }
}

export default Header
