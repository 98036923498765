import React from 'react'
import {Link} from 'gatsby'

import Icon from './Icon'
// import Notification from './Notification'
import { ContainedRow } from './UI'

const NavGroup = props => (
  <div className='col col-6 col-sm-4 col-lg-3'>
    <dl>
      <dt>{props.groupTitle}</dt>
      {props.items.map((item, index) => (
        <dd key={index}>
          {item.externalLink
            ? (
              <a
                href={item.path}
                className='site-footer-nav-link'
                target={item.target}
                rel='noopener'
              >
                {item.label}
              </a>
            )
            : (
              <Link
                to={item.path}
                className='site-footer-nav-link'
              >
                {item.label}
              </Link>
            )
          }
        </dd>
      ))}
    </dl>
  </div>
)

const Footer = props => (
  <footer className='site-footer'>
    <ContainedRow className='site-footer-navigation'>
      <div className='site-footer-logo col col-12 col-sm-4 col-lg-3'>
        <Link
          to='/'
        >
          <Icon type='logo-pink-icon' />
        </Link>
      </div>
      {props.footerNavigation.map((group, index) => (
        <NavGroup {...group} key={index} />
      ))}
    </ContainedRow>

    <div className='site-footer-copyright'>
      &copy; Tierion, Inc. All rights reserved.
      <Link to='/privacy-policy'>
        Privacy Policy
      </Link>
      <Link to='/tos'>
        Terms of Service
      </Link>
    </div>
  </footer>
)

export default Footer
