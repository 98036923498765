import find from 'lodash/find'

const getActiveNavObject = ({ navigation, location }) => {
  return find(navigation, (item) => {
    let { pathname } = location
    return item.path.replace('/', '') === pathname.split('/')[1]
  })
}

/**
 *
 * Import all files from a dir
 * @param object request https://webpack.js.org/guides/dependency-management/#context-module-api
 *
 */
const importAll = request => {
  const imports = {}

  request.keys().forEach((key, index) => {
    imports[key] = request.keys().map(request)[index]
  })

  return imports
}

const encodeData = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export {
  getActiveNavObject,
  importAll,
  encodeData
}
