import React from 'react'
import classnames from 'classnames'

import Icon from '../components/Icon'

const Section = props => {
  const classes = classnames(
    `section section-${props.className}`,
    props.className
  )

  return (
    <section className={classes} {...props.attributes}>
      {props.children}
    </section>
  )
}

const SectionTitle = props => {
  const classes = props.className || 'section-title col col-md-8'

  return (
    <div className='container'>
      <div className='row justify-content-md-center'>
        <SectionTitleHeadings
          {...props}
          className={classes}
        />
      </div>
    </div>
  )
}

const SectionTitleHeadings = props => {
  const { title, subtitle, className } = props

  return (
    <div className={className}>
      {title && <h1>{title}</h1>}
      {subtitle && <h2>{subtitle}</h2>}
    </div>
  )
}

const ContainedRow = props => {
  const className = props.className || ''
  return (
    <div className='container'>
      <div className={`row ${className}`}>
        {props.children}
      </div>
    </div>
  )
}

const LeadText = props => (
  <div
    className='paragraph'
    dangerouslySetInnerHTML={{__html: props.text}}
  />
)

const SectionText = props => (
  <Section {...props} className='section-text'>
    <SectionTitle {...props} />
    <div className='container'>
      <LeadText {...props} />
    </div>
  </Section>
)

const SectionTextList = props => (
  <Section {...props}>
    <SectionTitle {...props} />
    <div className='container'>
      <dl className='text-list row'>
        {props.listItems.map((item, index) =>
          <div className='col col-12 col-md-3 offset-md-1' key={index}>
            <dt>{item.title}</dt>
            <dd>{item.text}</dd>
          </div>
        )}
      </dl>
    </div>
  </Section>
)

const IconList = props => (
  <div className='container'>
    <ul className='icon-list row justify-content-between'>
      {props.featureList.map((item, index) =>
        <li className='icon-list-item col col-12 col-md-6 col-lg-5' key={index}>
          <div className='icon-list-item-image'>
            <img
              src={props.pageImgs[`./${item.icon}`]}
              alt='Feature List Item'
            />
          </div>
          <div>
            <div className='icon-list-item-title'>
              {item.title}
            </div>
            <div className='icon-list-item-text'>
              {item.text}
            </div>
          </div>
        </li>
      )}
    </ul>
  </div>
)

const CtaHero = props => {
  const containerClasses = classnames(
    'cta-hero container',
    props.containerClass
  )

  const contentClasses = classnames(
    'col',
    props.contentClass
  )

  const buttonClasses = classnames(
    'btn btn-tight',
    props.buttonClass
  )

  return (
    <div className={containerClasses}>
      <SectionTitle {...props} />
      <ContainedRow>
        <div className={contentClasses}>
          <p className='paragraph-large'>
            {props.text}
          </p>
          <a
            href={props.linkHref}
            className={buttonClasses}
            target='_blank'
            rel='noopener noreferrer'
          >
            {props.linkText}
            <Icon type='external-link' />
          </a>
        </div>
      </ContainedRow>
    </div>
  )
}

const PageHeader = props => (
  <Section className='page-header'>
    <ContainedRow className='justify-content-md-center'>
      <SectionTitleHeadings className='col col-md-10 col-lg-8' title={props.title} />
    </ContainedRow>
  </Section>
)

const PageContent = props => (
  <Section className='page-content'>
    <ContainedRow className='justify-content-md-center'>
      <div className='col col-md-10 col-lg-8'>
        {props.children}
      </div>
    </ContainedRow>
  </Section>
)

export {
  Section,
  SectionTitle,
  SectionTitleHeadings,
  ContainedRow,
  LeadText,
  SectionText,
  SectionTextList,
  IconList,
  CtaHero,
  PageHeader,
  PageContent
}
